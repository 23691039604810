import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutBlank from '@/components/layouts/LayoutBlank.vue'
import LayoutAdmin from '@/components/layouts/LayoutAdmin.vue'

//BOC:[state]
import store from "./../store/index"
//EOC
//BOC:[guard]
import multiguard from 'vue-router-multiguard';
const alreadyLoggedIn = function(to,from,next){
  if(store.state.auth.token && store.state.auth.id){
    // BOC : User already logged in 
    next({name:'PageHomeDashboard'})
  }
  next();
  // next({name:'PageGuestLanding'});
}
const loggedInOperation = function(to, from, next) {
  let allowedRoles = ['developer','vision_admin','customer_service','operation'];
  if (!store.state.auth.token || !allowedRoles.some(role=>store.state.auth.role.includes(role))) {
    next({ name: 'PageUnauthorizedUser'});
  }
  next();
}
const loggedIn = function(to, from, next) {
  let allowedRoles = ['developer','salesperson','vision_admin','customer_service','operation','sales_manager','sales_team_lead','marketing'];
  if (!store.state.auth.token || !allowedRoles.some(role=>store.state.auth.role.includes(role))) {
    next({ name: 'PageUnauthorizedUser'});
  }
  next();
}

const developerLimit = function(to,from,next){
  let allowedRoles = ['developer'];
  if(!store.state.auth.token || !allowedRoles.some(role=>store.state.auth.role.includes(role)))
  {
    next({name:'PageUnauthorizedUser'});
  }
  next();
}

const limit = function(to,from,next)
{
  let allowedRoles = ['developer','vision_admin','sales_team_lead','operation','sales_manager','sales_team_lead',"marketing"];
  if(!store.state.auth.token || !allowedRoles.some(role=>store.state.auth.role.includes(role)))
  {
    next({name:'PageUnauthorizedUser'});
  }
  next();
}
//EOC

Vue.use(VueRouter)

//BOC:[locale] for language
var pathArray = window.location.pathname.split('/')
var locale = pathArray[1]
var path2 = pathArray[2]
if (locale != '' && (path2 == null || path2 == '')) {
  window.location.href = window.location.origin
}
//EOC

const routes = [
  {
    path: '/',
    component: LayoutBlank,
    children: [
      {
        path: '',
        name: 'PageGuestLanding',
        // beforeEnter:multiguard([alreadyLoggedIn]),
        component: () =>
          import ('@/views/guest/PageGuestLanding.vue'),
      },
      {
        path:'/mockup',
        component:LayoutAdmin,
        children:[
         
          {
            path:'form/proofofpayment',
            name:'MockupProofOfPayment',
            component: () => import('@/views/mockup/ProofOfPaymentForm.vue')
          },
          {
            path:'team/salesteam/register',
            name:'MockupSalesTeam',
            component:()=>import('@/views/mockup/SalesTeamMockup.vue'),
          },
          {
            path:'/team/salesteam/findsaleperson',
            name:"MockupFindSalesperson",
            component:()=>import('@/views/mockup/SearchSalesperson.vue')
          }
        ]
      },
      {
        path:'/template',
        component:LayoutAdmin,
        beforeEnter:multiguard([developerLimit]),
        children:[
          {
            path:'browse',
            name:'TemplateBrowse',
            component:()=>import('@/views/template/Browse.vue'),
          }
        ]
      },
      {
        path: 'home',
        name: 'PageGuestHome',
        beforeEnter:multiguard([alreadyLoggedIn]),
        component: () =>
          import ('@/views/guest/PageLogin.vue'),
      },
      {
        path:'login',
        name:'PageLoginMockup',
        component:()=> import('@/views/guest/PageLogin.vue'),
      },
      {
        path:'password/reset/:token',
        component:()=>import('@/views/user/ResetPassword.vue'),
      }
    ],
  },
  
  {
    path:'/developer',
    component:LayoutAdmin,
    beforeEnter:multiguard([developerLimit]),
    children:[
      {
        path:'component/taskcomplete',
        name:'ComponentTaskComplete',
        component:()=>import('@/components/common/ATaskComplete.vue'),
      },
      {
        path:'component/activetask',
        name:'ComponentActiveTask',
        component:()=>import('@/components/task/ComponentActiveTask.vue'),
      },
      {
        path:'component/attachment/dropzone',
        name:'ComponentAttachmentDropzone',
        component:()=>import('@/components/attachment/ComponentAttachmentDropzone.vue'),
      },
      {
        path:'referral/settings',
        name:'PageReferralSettings',
        component:()=>import('@/views/referral/PageReferralSettings.vue'),
      },
      {
        path:'invoice',
        name:'PageDeveloperInvoice',
        component:()=>import('@/views/developer/PageDeveloperCompaniesInvoice.vue'),
      },
      {
        path:'features/list',
        name:'PageFeaturesList',
        component:()=>import('@/views/developer/PageDeveloperFeatures.vue'),
      },
      {
        path:'features/detail/:id',
        name:'PageFeaturesDetail',
        component:()=>import('@/views/developer/PageDeveloperFeaturesUpdate.vue'),
      },
      {
        path:'component/companies/report/v2',
        name:'PageCompaniesReportv2',
        component:()=>import('@/components/developer/prototype/ComponentPrototypeReportCompanies.vue')
      },
      {
        path:'component/internal/staff',
        name:'ComponentStaffListv2',
        component:()=>import('@/components/staff/ComponentStaffListv2.vue'),
      },
      { 
        path:'component/datatable/serverside',
        name:'ComponentTable',
        component:()=>import('@/components/table/ComponentTable.vue'),
      },
      {
        path:'dashboard',
        name:'PageDeveloperDashboard',
        component:()=>import('@/views/dashboard/PageAdminDashboard.vue'),
      },
      {
        path:'renewal',
        name:'PageDeveloperRenewalList',
        component:()=>import('@/views/renewal/PageRenewalList'),
      },
      {
        path:'pic/new/:id',
        name:'PageDeveloperPICCompaniesList',
        component:()=>import('@/views/pic/PagePICCompaniesList.vue'),
      },
      {
        path:'pic/choose',
        name:'PageDeveloperPICCompaniesChoose',
        component:()=>import('@/views/pic/PagePICCompaniesChoose.vue'),
      },
      {
        path:'companies',
        name:'PageDeveloperCompaniesList',
        component:()=>import('@/views/developer/PageDeveloperCompaniesList.vue'),
      },
      {
        path:'companies/clean',
        name:'PageDeveloperCompaniesClean',
        component:()=>import('@/views/developer/PageDeveloperCompaniesClean.vue'),
      },
      {
        path:'certificate/generate',
        name:'PageDeveloperGenerateCertificate',
        component:() => import('@/views/developer/PageDeveloperGenerateCertificate.vue'),
      },
      {
        path:'certificate/detail/:id',
        name:'PageDeveloperDetailCertificate',
        component:() => import('@/components/certificate/ComponentDetailCertificate.vue'),
      },
      {
        path:'excel/industry',
        name:'PageDeveloperUploadExcel',
        component:()=>import('@/views/developer/PageDeveloperUploadExcel.vue'),
      },
      {
        path:'excel/industry/matched',
        name:'PageDeveloperIndustryMatchList',
        component:()=>import('@/views/developer/PageDeveloperIndustryMatchList.vue'),
      },
      {
        path:'excel/user/matched',
        name:'PageDeveloperUserList',
        component:()=>import('@/views/developer/PageDeveloperUserList.vue'),
      },
      {
        path:'excel/vision_key',
        name:'PageDeveloperVisionKey',
        component:()=>import('@/views/developer/PageDeveloperVisionKey.vue'),
      },
      {
        path:'excel/staffList',
        name:'PageDeveloperStaffList',
        component:()=>import('@/views/developer/PageDeveloperStaffList.vue'),
      },
      {
        path:'excel/subscriptionhistory',
        name:'PageDeveloperSubscriptionHistory',
        component:()=>import('@/views/developer/PageDeveloperSubscriptionHistory.vue'),
      },
      {
        path:'excel/upload/subscriptionhistory',
        name:'PageDeveloperUploadExcelSubscriptionHistory',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelSubscriptionHistory.vue'),
      },
      {
        path:'excel/refund',
        name:'PageDeveloperCompaniesRefund',
        component:()=>import('@/views/developer/PageDeveloperCompaniesRefund.vue'),
      },
      {
        path:'excel/upload/refund',
        name:'PageDeveloperUploadExcelCompaniesRefund',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelCompaniesRefund.vue'),
      },
      {
        path:'excel',
        name:'PageDeveloperExcelList',
        component:()=>import('@/views/developer/PageDeveloperExcelList.vue'),
      },
      {
        path:'excel/onboarding',
        name:'PageDeveloperUsersOnboarding',
        component:()=>import('@/views/developer/PageDeveloperUsersOnboarding.vue'),
      },
      {
        path:'excel/comment',
        name:'PageDeveloperComment',
        component:()=>import('@/views/developer/PageDeveloperComment.vue'),
      },
      {
        path:'excel/upload/onboarding',
        name:'PageDeveloperUploadExcelUsersOnboarding',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelUsersOnboarding.vue'),
      },
      {
        path:'excel/accountType',
        name:'PageDeveloperAccountType',
        component:()=>import('@/views/developer/PageDeveloperAccountType.vue'),
      },
      {
        path:'excel/reporting',
        name:'PageDeveloperCompaniesReporting',
        component:()=>import('@/views/developer/PageDeveloperCompaniesReporting.vue'),
      },
      {
        path:'excel/upload/reporting',
        name:'PageDeveloperUploadExcelCompaniesReporting',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelCompaniesReporting.vue'),
      },
      {
        path:'excel/sales',
        name:'PageDeveloperCompaniesSales',
        component:()=>import('@/views/developer/PageDeveloperCompaniesSales.vue'),
      },
      {
        path:'excel/upload/sales',
        name:'PageDeveloperUploadExcelCompaniesSales',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelCompaniesSales.vue'),
      },
      {
        path:'excel/hrdc',
        name:'PageDeveloperCompaniesHRDC',
        component:()=>import('@/views/developer/PageDeveloperCompaniesHRDC.vue'),
      },
      {
        path:'excel/upload/hrdc',
        name:'PageDeveloperUploadExcelCompaniesHRDC',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelCompaniesHRDC.vue'),
      },
      {
        path:'excel/aae',
        name:'PageDeveloperCompaniesAAE',
        component:()=>import('@/views/developer/PageDeveloperCompaniesAAE.vue'),
      },
      {
        path:'excel/upload/aae',
        name:'PageDeveloperUploadExcelCompaniesAAE',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelCompaniesAAE.vue'),
      },
      {
        path:'survey',
        name:'PageDeveloperSurvey',
        component:()=>import('@/views/developer/PageDeveloperSurvey.vue')
      },
      {
        path:'dropdown',
        name:'PageDeveloperDropdown',
        component:()=>import('@/views/developer/PageDeveloperDropdown.vue'),
      },
      {

        path:'excel/funnel',
        name:'PageDeveloperCompaniesFunnel',
        component:()=>import('@/views/developer/PageDeveloperCompaniesFunnel.vue'),
      },
      {
        path:'excel/upload/funnel',
        name:'PageDeveloperUploadExcelCompaniesFunnel',
        component:()=>import('@/views/developer/PageDeveloperUploadExcelCompaniesFunnel.vue'),
      },
      {
        path:'companies/detail/v2/:id',
        name:'PageDeveloperCompaniesDetailv2',
        component:()=>import('@/views/developer/PageDeveloperCompaniesDetailv2.vue'),
      },
      {
        path:'renewal',
        name:'PageDeveloperRenewal',
        component:()=>import('@/views/report/PageRenewalReport.vue'),
      },
      {
        path:"coupon/appied",
        name:"PageDeveloperCouponAppliedReport",
        component:()=> import("@/views/developer/PageDeveloperCouponAppliedReport.vue"),
      },
      {
        path:"subscriber/activeinactive",
        name:'PageSubscriberActiveInactive',
        component:() => import("@/views/developer/report/PageSubscriberActiveInactive.vue"),
      },
      {
        path:"loginandengagement",
        name:"PageLoginAndEngagement",
        component:() => import("@/views/developer/report/PageLoginAndEngagement.vue"),
      },
      {
        path:"payment/failed",
        name:"PagePaymentFailed",
        component:()=> import("@/views/developer/report/PagePaymentFailed.vue"),
      }
    ]
  },
  {
    path:'/payment',
    component:LayoutAdmin,
    children:[
      {
        path:'credit_card/remove/:id',
        name:'PageCreditCardDelete',
        component:()=> import('@/views/company/PageCreditCardDelete.vue'),
      },
    ]
  },
  {
    path:'/mockup',
    component:LayoutAdmin,
    children:[
      {
        path:'form/payment',
        name:'MockupFormPayment',
        component: () => import('@/views/mockup/PaymentStatusUpdateForm.vue'),
      },
      // {
      //   path:'whatssap/create',
      //   name:'MockupWhatssapCreate',
      //   component:()=>import('@/views/mockup/PageWhatssapCreate.vue')
      // },
      // {
      //   path:'whatssap/template/list',
      //   name:'MockupWhatssapTemplateList',
      //   component:()=>import('@/views/mockup/PageWhatssapTemplateList.vue'),
      // },
      {
        path:'form/renewal',
        name:'MockupFormRenewal',
        component: () => import('@/views/mockup/MockupCompanyDetail.vue'),
      },
      {
        path:'form/renewal/two',
        name:'MockupFormRenewalTwo',
        component: () => import('@/views/mockup/MockupCompanyDetailVersionTwo.vue'),
      },
      {
        path:'form/renewal/three',
        name:'MockupFormRenewalThree',
        component: () => import('@/views/mockup/MockupCompanyDetailVersionThree.vue'),
      },
      {
        path:'model/update',
        name:'MockupModelUpdate',
        component: () => import('@/views/mockup/MockupUpdateModel.vue'),
      },
      // {
      //   path:'pic/list',
      //   name:'MockupPICList',
      //   component: () => import('@/views/mockup/PagePICListMockup.vue'),
      // },
      // {
      //   path:'pic/create',
      //   name:'MockupPICCreate',
      //   component:()=>import('@/views/mockup/PagePICCreateMockup.vue'),
      // },
      // {
      //   path:'pic/update',
      //   name:'MockupPICUpdate',
      //   component:()=>import('@/views/mockup/PagePICUpdateMockup.vue'),
      // },
      // {
      //   path:'company/detail',
      //   name:'MockupCompanyDetail',
      //   component:()=>import('@/views/mockup/CompanyDetailNewMockup'),
      // },
      {
        path:'company/logs/:id',
        name:'MockupCompanyLogs',
        component:()=>import('@/components/company/CompanyLogs.vue')
      },
      // {
      //   path:'user/list',
      //   name:'MockupUserList',
      //   component: () => import('@/views/mockup/PageUserListMockup.vue')
      // },
    ]
  },
  {
    path:'/home',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'dashboard',
        name:'PageHomeDashboard',
        component:()=>import('@/views/dashboard/PageDashboard.vue'),
      },
      {
        path:'dashboard/two',
        name:'PageHomeDashboardTwo',
        component:()=>import('@/views/dashboard/PageDashboard.vue'),
      }
    ]
  },
  {
    path:'/tracker',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children : [
      {
        path:'webinar/failed/user',
        name:'PageTrackUserFailedRegisterWebinar',
        component:()=>import('@/views/developer/PageTrackUserFailedWebinarRegister.vue'),
      },
      {
        path:'search/keyword',
        name:"PageTrackSearchKeyword",
        component:()=>import('@/views/developer/PageTrackSearchKeyword.vue'),
      },
    ]
  },
  {
    path:'/form',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'batch/company/:id',
        name:'PageUpdateFormBatch',
        component:()=>import('@/views/form/PageMassInsertForm.vue'),

      },
      {
        path:'batch/user',
        name:'PageUpdateUserFormBatch',
        component:()=>import('@/views/form/PageMassInsertUserForm.vue'),
      }
    ]
  },
  {
    path:'/survey',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageSurveyList',
        component:()=>import('@/views/survey/PageSurveyList.vue'),
      },
      {
        path:'settings/:id',
        name:'PageSurveySettingDetail',
        component:()=>import('@/views/survey/PageSurveySetting.vue'),
      },
      {
        path:'userdetail',
        name:'PageUserDetailSurveyList',
        component:()=>import('@/views/survey/PageUserDetailSurveyList.vue'),
      },
      {
        path:'feedback',
        name:'PageFeedbackSurveyList',
        component:()=>import('@/views/survey/PageFeedbackSurveyList.vue'),
      },
      {
        path:'finance',
        name:'PageFinanceSurveyList',
        component:()=>import('@/views/survey/PageFinanceSurveyList.vue'),
      }
    ]
  },
  {
    path:'/dropdown',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageDropdownList',
        component:()=>import('@/views/dropdown/PageDropdown.vue'),
      },
    ]
  },
  {
    path:'/referral',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'claim/reward',
        name:'PageClaimRewardList',
        component:()=>import('@/views/referral/PageClaimRewardList.vue'),
      },
      {
        path:'reward',
        name:'PageRewardList',
        component:()=>import('@/views/referral/PageRewardList.vue'),
      },
      {
        path:'reward/new',
        name:"PageRewardCreate",
        component:()=>import('@/views/referral/PageRewardCreate.vue'),
      },
      {
        path:'reward/detail/:id',
        name:'PageRewardDetail',
        component:()=>import('@/views/referral/PageRewardDetail.vue'),
      },
      {
        path:'claim/reward/detail/:id',
        name:'PageClaimRewardDetail',
        component:()=>import('@/views/referral/PageClaimRewardDetail.vue'),
      },{
        path:'demonstration',
        name:'PageDemonstrationList',
        component:()=>import('@/views/demonstration/PageDemonstrationList.vue'),
      },
      {
        path:'demonstration/new',
        name:"PageDemonstrationCreate",
        component:()=>import('@/views/demonstration/PageDemonstrationCreate.vue'),
      },
      {
        path:'demonstration/detail/:id',
        name:'PageDemonstrationDetail',
        component:()=>import('@/views/demonstration/PageDemonstrationDetail.vue'),
      },
      {
        path:'demonstration/update/:id',
        name:"PageDemonstrationUpdate",
        component:()=>import('@/views/demonstration/PageDemonstrationUpdate.vue'),
      },
      {
        path:'transaction',
        name:'PageTransactionList',
        component:()=>import('@/views/referral/PageTransactionList.vue'), 
      },
      {
        path:'transaction/new',
        name:'PageTransactionNew',
        component:()=>import('@/views/referral/PageTransactionCreate.vue'),
      },

    ]
  },
  {
    path:'/refund',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'request/:id',
        name:'PageRefundForm',
        component:()=>import('@/views/form/PageRefundForm.vue'),
      }
    ]
  },
  {
    path:'/manual',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageManualList',
        component:()=>import('@/views/manual/PageManualList.vue'),
      },
      {
        path:'new',
        name:'PageManualCreate',
        component:()=>import('@/views/manual/PageManualCreate.vue'),
      },
      {
        path:'detail/:id',
        name:'PageManualDetail',
        component:()=>import('@/views/manual/PageManualDetail.vue'),
      },
      {
        path:'update/:id',
        name:'PageManualUpdate',
        component:()=>import('@/views/manual/PageManualUpdate.vue')
      },
    ]
  },
  {
    path:'/report',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'renewal',
        name:'PageReportRenewalList',
        component:()=> import('@/views/renewal/PageRenewalList.vue'),
      },
      {
        path:'companies',
        name:'PageCompaniesReport',
        component:()=> import('@/views/report/PageCompaniesReport.vue'),
      },
      {
        path:'marketing',
        name:'PageMarketingReport',
        component:()=>import('@/views/report/PageMarketingReport.vue'),
      }, 
      {
        path:'company/new/subscription',
        name:'PageNewCompaniesSubscriptionList',
        component:()=>import('@/views/company/PageNewCompaniesSubscriptionList.vue')
      },
      {
        path:'company/pic',
        name:'PagePICReport',
        component:()=>import('@/views/report/PagePICReport.vue'),
      },
      {
        path:'company/new/renewal',
        name:'PageNewRenewalReport',
        component:()=>import('@/views/renewal/PageNewRenewalReport.vue'),
      },
      {
        path:'company/unassigned',
        name:'PageUnassignedCompanies',
        component:()=>import('@/views/report/PageUnassignedCompanies'),
      },
      {
        path:'loginanalysis',
        name:'PageLoginAnalysis',
        component:()=>import('@/views/user/PageLoginAnalysis.vue'),
      },
    ],
  },
  {
    path:'/analysis',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'general',
        name:'PageAnalysis',
        component:()=>import('@/views/user/PageAnalysis.vue'),
      },
      {
        path:'weekly',
        name:'PageDaysAnalysis',
        component:()=>import('@/views/user/PageDaysAnalysis.vue'),
      },
      {
        path:'company',
        name:'PageCompaniesListAnalysis',
        component:()=>import('@/views/analysis/PageCompaniesList.vue'),
      }
    ],
  },
  {
    path:'/chart',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'performance',
        name:'PagePerformanceChart',
        component:()=>import('@/views/chart/PagePerformanceChart.vue'),
      },
      {
        path:'trending/video',
        name:'PageTrendingVideoChart',
        component:()=>import('@/views/chart/PageTopVideosChart.vue'),
      },
    ]
  },
  {
    path:'/manualstep',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:':id',
        name:'PageManualStepList',
        component:()=>import('@/views/manual/PageManualStepList.vue'),
      },
      {
        path:'new/:id',
        name:'PageManualStepCreate',
        component:()=>import('@/views/manual/PageManualStepCreate.vue'),
      },
      {
        path:'update/:id',
        name:'PageManualStepUpdate',
        component:()=>import('@/views/manual/PageManualStepUpdate.vue'),
      }
    ]
  },
  {
    path:'/AAE',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'Add/:id',
        name:'PageAAEMinutesAdd',
        component:()=>import("@/views/AAE/PageAAEMinutesAdd.vue"),
      },
      {
        path:'minute/list/:id',
        name:'PageMinuteRecordList',
        component:()=>import("@/views/AAE/PageMinuteRecordList.vue"),
      },
    ]
  },
  {
    path:'/role',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'update/:id',
        name:'PageRoleUpdate',
        component:()=>import('@/views/role/PageRoleUpdate.vue'),
      }

    ]
  },
  {
    path:'/webinar',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageWebinarList',
        component:()=>import('@/views/webinar/PageWebinarList.vue'),
      },
      {
        path:'participants/:id',
        name:'PageWebinarParticipants',
        component:()=>import('@/views/webinar/PageWebinarParticipantsList.vue'),
      },
    ]
  },
  {
    path:'/task',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'user/:id',
        name:'PageTaskListUser',
        component:()=>import('@/views/task/PageTaskListUser.vue'),
      },
      {
        path:'company/:id',
        name:'PageTaskList',
        component:()=>import('@/views/task/PageTaskList.vue'),
      },
      {
        path:'new/:id',
        name:'PageTaskCreate',
        component:()=>import('@/views/task/PageTaskCreate.vue'),
      },
      {
        path:'mass/new/:id',
        name:'PageTaskMassAssign',
        component:()=>import('@/views/task/PageTaskMassAssign.vue')
      },
      {
        path:'detail/:id',
        name:'PageTaskDetail',
        component:()=>import('@/views/task/PageTaskDetail.vue'),
      },
      {
        path:'update/:id',
        name:'PageTaskUpdate',
        component:()=>import('@/views/task/PageTaskUpdate.vue'),
      },
    ]
  },

  {
    path:'/attachment',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageAttachmentList',
        component:()=>import('@/views/attachment/PageAttachmentList.vue'),
      },
      {
        path:'receipt',
        name:'PageReceiptList',
        component:()=>import('@/views/attachment/PageReceiptList.vue'),
      },
      {
        path:'invoice',
        name:'PageInvoiceList',
        component:()=>import('@/views/attachment/PageInvoiceList.vue'),
      },
      {
        path:'xero/invoice',
        name:'PageInvoiceXeroList',
        component:() => import('@/views/attachment/PageInvoiceXeroList.vue')
      },
      {
        path:'xero/invoice/upload',
        name:'PageUploadXeroInvoice',
        component:()=>import('@/views/attachment/PageUploadXeroInvoice.vue'),
      },
      {
        path:'xero/invoice/send/:id/:company_id',
        name:'PageSendXeroInvoice',
        component:()=>import('@/views/attachment/PageSendXeroInvoice.vue'),
      },
      // {
      //   path:'xero/invoice/new',
      //   name:'PageCreateXeroInvoice',
      //   component:()=>import('@/views/attachment/PageCreateXeroInvoice'),
      // },
      {
        path:'xero/invoice/edit/:id/:company_id/:type',
        name:'PageEditXeroInvoice',
        component:() => import('@/views/attachment/PageEditXeroInvoice.vue'),
      },
      {
        path:'refund',
        name:'PageRefundList',
        component:()=>import('@/views/attachment/PageRefundList.vue')
      },
      {
        path:'upload/:attachment/:id',
        name:'PageReceiptUpload',
        component:()=>import('@/views/attachment/PageAttachmentUpload.vue'),
      },
      {
        path:'company/:id',
        name:'PageAttachmentFormCompany',
        component:()=>import('@/views/attachment/PageAttachmentFormCompany.vue'),
      },
      // {
      //   path:'detail',
      //   name:'PageReceiptDetail',
      //   component:()=>import('@/views/receipt/PageReceiptDetail.vue'),
      // },
    ]
  },
  {
    path:'/user',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageUserList',
        component:()=>import('@/views/user/PageUserList.vue')
      },
      {
        path:'new/company/:id',
        name:'PageUserCreate',
        component:()=>import('@/views/user/PageUserCreate.vue'),
      },
      {
        path:'client/update/:id',
        name:'PageUserUpdate',
        component:()=>import('@/views/user/PageUserUpdate.vue'),
      },
      {
        path:'client/swapcompany/:id',
        name:'PageSwapUser',
        component:()=>import('@/views/user/PageSwapUser.vue'),
      },
      {
        path:'client/update/single/:id',
        name:'PageSingleUserUpdate',
        component:() => import('@/views/user/PageSingleUserUpdate.vue'),
      }
    ]
  },
  // {
  //   path:'specialremark',
  //   component:LayoutAdmin,
  //   beforeEnter:multiguard([loggedIn]),
  //   children:[
  //     {
  //       path:'update/:id',
  //       name:'PageUpdateCompaniesRemark',
  //       component:()=>import('@/views/company/PageUpdateCompaniesRemark.vue'),
  //     },
  //   ]
  // },
  {
    path:'/team',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageTeamList',
        component:()=>import('@/views/team/PageTeamList.vue'),
      },
      {
        path:'new',
        name:'PageTeamCreate',
        beforeEnter:multiguard([limit]),
        component:()=>import('@/views/team/PageTeamCreate.vue'),
      },
      {
        path:'detail',
        name:'PageTeamDetail',
        component:()=>import('@/views/team/PageTeamDetail.vue'),
      },
      {
        path:'update',
        name:'PageTeamUpdate',
        component:()=>import('@/views/team/PageTeamUpdate.vue')
      }
    ]
  },
  {
    path:'/followup',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'create/:id',
        name:'PagePICFollowupCreate',
        component:()=>import('@/views/pic/PagePICFollowupCreate.vue'),
      },
    ]
  },
  {
    path:'/pic',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedInOperation]),
    children:[
      {
        path:'companies',
        children:
        [
          {
            path:'list',
            name:'PagePicCompaniesList',
            component:()=>import('@/views/pic/PagePICCompaniesList.vue'),
          },
    
        ]
      },
      {
        path:'assign/:id',
        name:'PagePICCompaniesAssign',
        component:()=>import('@/views/pic/PagePICCompaniesList.vue')
      },
      {
        path:'',
        name:'PagePicList',
        component:()=>import('@/views/pic/PagePICList.vue'),
      },
      {
        path:'create',
        name:'PagePicCreate',
        beforeEnter:multiguard([limit]),
        component:()=>import('@/views/pic/PagePICCreate.vue'),
      },
      {
        path:'update/:id',
        name:'PagePicUpdate',
        component:()=>import('@/views/pic/PagePICUpdate.vue'),
      },

    ]
  },
  {
    path:'/subscription',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children : [
      {
        path:'scheduler',
        name:'PageSubscriptionScheduler',
        component:()=>import('@/views/subscription/PageSubscriptionScheduler.vue'),
      },
      {
        path:'scheduler/edit/:id',
        name:'PageEditSubscriptionScheduler',
        component:()=>import('@/views/subscription/PageEditSubscriptionScheduler.vue'),
      }
    ]
  },
  {
    path:'/certificate',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'generate/:id',
        name:'PageGenerateCertificate',
        component:()=>import('@/views/certificate/PageGenerateCertificate.vue'),
      }
    ]
  },
  {
    path:'/notification',
    component:LayoutAdmin,
    beforeEnter:multiguard[(loggedIn)],
    children:[
      {
        path:'',
        name:'PageNotificationList',
        component:()=> import('@/views/notification/PageNotificationList.vue'),
      },
      {
        path:'detail/:id',
        name:'NotificationDetail',
        component:()=>import('@/views/notification/PageNotificationDetail.vue'),
      },
      {
        path:'tick/:id',
        name:'NotificationTick',
        component:()=>import('@/views/notification/PageNotificationTick'),
      }
    ]
  },
  {
    path:'/feedback',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'',
        name:'PageFeedbackForm',
        component:()=>import('@/views/feedback/PageFeedbackForm.vue'),
      }
    ],
  },
  {
    path:'/coupon',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'failed',
        name:'PageReApplyAAECoupon',
        component:()=>import('@/views/AAE/PageReApplyAAECoupon.vue'),
      }
    ],
  },
  {
    path:'/email',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'send/company/:id',
        name:'PageSendEmail',
        component:()=>import('@/views/email/PageSendEmail.vue'),
      },
      {
        path:'send/user/:id',
        name:'PageUserSendEmail',
        component:()=>import('@/views/email/PageUserSendEmail.vue'),
      },
    ]

  },
  {
    path:'/whatssap',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'create/:id',
        name:'PageWhatssapCreate',
        component:()=>import('@/views/whatssap/PageWhatssapCreate.vue'),
      },
      {
        path:'template/list',
        name:'PageWhatssapTemplateList',
        component:()=>import('@/views/whatssap/PageWhatssapTemplateList.vue'),
      },
      {
        path:'template/create',
        name:'PageWhatssapTemplateCreate',
        component:()=>import('@/views/whatssap/PageWhatssapTemplateCreate.vue'),
      },
      {
        path:'template/update/:id',
        name:'PageWhatssapTemplateUpdate',
        component:()=>import('@/views/whatssap/PageWhatssapTemplateUpdate.vue'),
      }
    ]
  },
  {
    path:'/staff',
    component:LayoutAdmin,
    beforeEnter:multiguard([loggedIn]),
    children:[
      {
        path:'detail/:id',
        name:'PageStaffDetail',
        component: () => import('@/components/model/ModelDetail.vue'),
      },
      {
        path:'update/:id',
        name:'PageStaffUpdate',
        component:()=> import('@/views/user/PageSingleStaffUpdate.vue')
      },
      {
        path:'create/:id',
        name:'PageStaffCreate',
        component:()=>import('@/views/user/PageStaffCreate.vue'),
      },
      {
        path:'/role/update/:id',
        name:'PageStaffRole',
        component:()=>import('@/views/role/PageRoleUpdate.vue'),
      },
      {
        path:'',
        name:'PageAdminStaff',
        beforeEnter:multiguard([loggedIn]),
        component:() =>
        import('@/views/staff/PageStaffList.vue'),
      },
      {
        path:'salesperson',
        name:'PageSalespersonList',
        component:()=> import('@/views/staff/PageStaffList.vue'),
      },
      {
        path:'customer_service',
        name:'PageCustomerServiceList',
        component:()=>import('@/views/staff/PageStaffList.vue'),
      },
      {
        path:'vision_admin',
        name:'PageVisionAdminList',
        component:()=>import('@/views/staff/PageStaffList.vue'),
      }
    ]
  },
  {
    path: '/home',
    component: LayoutAdmin,
    beforeEnter: multiguard([loggedIn]),
    children: [
      {
        path: '',
        redirect:{name:'PageHomeDashboard'},
      },
      // {
      //   path:'dashboard/two',
      //   name:'PageAdminDashboardTwo',
      //   component:()=>import('@/views/admin/PageAdminDashboardMockup.vue'),
      // },
      // {
      //   path: 'dashboard',
      //   name: 'PageAdminDashboard',
      //   component: () =>
      //     import ('@/views/admin/PageAdminDashboard.vue'),
      //   meta: {
      //     title: 'dashboard',
      //   },
      // },
      {
        path: 'logout',
        name: 'PageAdminLogout',
        component: () =>
          import ('@/views/admin/PageAdminLogout.vue'),
      },
      
    
      {
        path:'companies',
        name:'PageCompaniesList',
        component:()=>
        import('@/views/company/PageCompaniesList.vue'),
      },
      {
        path:'companies/remark/:id',
        name:'PageCompaniesRemark',
        component:()=>import('@/views/company/PageCompaniesRemark.vue'),
      },
      {
        path:'companies/remark/view/:id',
        name:'PageCompaniesViewRemark',
        component:()=>import('@/views/company/PageCompaniesViewRemark.vue'),
      },
      // {
      //   path:'companies/two',
      //   name:'PageCompaniesListTwo',
      //   component:()=> import('@/views/company/PageCompaniesListTwo.vue'),
      // },
      {
        path:'companies/new',
        name:'PageCompaniesCreate',
        component:()=>import('@/views/company/PageCompaniesCreate.vue'),
      },
      {
        path:'companies/edit/:id',
        name:"CompaniesEdit",
        component : () =>
        import('@/components/company/CompanyUpdate.vue')
      },
      {
        path:'companies/update/:id',
        name:'PageCompaniesUpdate',
        component:()=> import('@/views/company/PageCompaniesUpdate.vue'),
      },
      {
        path:'companies/detail/:id',
        name:'PageCompaniesDetail',
        component:()=> import('@/views/company/PageCompaniesDetail.vue'),
      },
      {
        path:'companies/detail/v1/:id',
        name:'PageCompaniesDetailv1',
        component:()=> import('@/views/company/PageCompaniesDetailv1.vue'),
      },
      {
        path:'companies/subscriptionhistory/list/:id',
        name:'PageSubscriptionHistoryList',
        component:()=> import('@/views/company/PageSubscriptionHistoryList.vue'),
      },
      {
        path:'companies/aae/list/:id',
        name:'PageAAEList',
        component:() => import('@/views/company/PageAAEList.vue'),
      },
      {
        path:'companies/sales/list/:id',
        name:'PageSalesList',
        component:()=>import('@/views/company/PageSalesList.vue')
      },
      {
        path:'companies/funnel/list/:id',
        name:'PageFunnelList',
        component:()=>import('@/views/company/PageFunnelList.vue')
      },
      {
        path:'companies/hrdc/list/:id',
        name:'PageHRDCList',
        component:() => import('@/views/company/PageHRDCList.vue'),
      },
      {
        path:'companies/invoice/list/:id',
        name:'PageCompanyInvoiceList',
        component:()=>import('@/views/company/PageInvoiceList.vue'),
      },
      {
        path:'companies/refund/list/:id',
        name:'PageCompanyRefundList',
        component:() => import('@/views/company/PageRefundList'),
      },
      {
        path:'companies/subscriptionhistory/:id',
        name:'PageCompaniesSubscriptionHistoryUpdate',
        component:()=> import('@/views/form/PageUpdateSubscriptionHistory.vue'),
      },
      {
        path:'companies/subscription/:id',
        name:'CompaniesSubscription',
        component:() => import('@/components/company/CompanySubscription.vue')
      },
      {
        path:'companies/subscription/activate/:id',
        name:'PageActivateSubscription',
        component:() => import('@/views/company/PageActivateSubscription.vue'),
      },
      {
        path:'companies/subscription/cancel/:id',
        name:'PageCancelSubscription',
        component:() => import('@/views/company/PageCancelSubscription.vue'),
      },
      {
        path:'companies/form/:id',
        name:'CompaniesForm',
        component:()=> import('@/views/form/Index.vue'),
      },
      {
        path:'companies/form/renewal/:id',
        name:'CompaniesRenewalUpdate',
        component:()=> import('@/components/company/CompanyRenewalUpdate.vue'),
      },
      {
        path:'companies/form/payment/:id',
        name:'CompaniesPaymentUpdate',
        component:()=> import('@/components/company/CompanyPaymentUpdate.vue'),
      }
      // {
      //   path:'companies/form/payment/companyId',
      //   name:'CompaniesPaymentUpdate',
      //   component:()=>import('@/components/company/CompanyPaymentUpdate.vue')
      // }

    ],
  },
  {
    path: '',
    component: LayoutBlank,
    children: [
      {
        path: 'error/expired',
        name: 'PageErrorSessionExpired',
        component: () =>
          import ('@/views/error/PageErrorSessionExpired.vue')
      },
      {
        path:'error/unauthorized',
        name:'PageUnauthorizedUser',
        component:()=>
          import('@/views/error/PageUnauthorizedUser.vue')
      },
      {
        path: '/:catchAll(.*)',
        name: 'PageErrorNotFound',
        component: () =>
          import ('@/views/error/PageErrorNotFound.vue')
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: locale,
  routes
})

//BOC:[page title]
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = ((to.meta.title) ? to.meta.title : '') + process.env.VUE_APP_NAME;
  });
});
//EOC

export default router